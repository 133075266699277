import Footer from '../../Components/Footer/Footer';
import './Products.scss';
import products from '../../Data/products';
import React, { useState, useEffect } from 'react';
import ProductCard from '../../Components/ProductCard/ProductCard';

function Products() {
    
    const [fProducts, setfProdcuts] = useState([]);
    const [buttonPressed, setButtonPressed] = useState('all');
    const [subCatButtonPressed, setsubCatButtonPressed] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [subCategory, setSubCategory] = useState({});


    const handleButtonClick = (e) => {
        e.preventDefault();
        setButtonPressed(e.target.value);
        setSearchTerm('')
        setsubCatButtonPressed('')
        const buttons = document.querySelectorAll('.nav-bar button');
        buttons.forEach(button => {
            if (button === e.target) {
                button.classList.add('selected-button');
            } else {
                button.classList.remove('selected-button');
            }
        });
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleSubCatButtonClick = (e) => {
        e.preventDefault();
        setsubCatButtonPressed(e.target.value);
        const subButtons = document.querySelectorAll('.subcategory button');
        subButtons.forEach(button => {
            if (button === e.target) {
                button.classList.add('selected-button');
            } else {
                button.classList.remove('selected-button');
            }
        });

    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() =>{
        const categoriesWithSubcategories = products.reduce((acc, product) => {
            if (!acc[product.category]) {
                acc[product.category] = [];
            }
            if (!acc[product.category].includes(product.subcategory)) {
                acc[product.category].push(product.subcategory);
            }
            return acc;
        }, {});
        setSubCategory(categoriesWithSubcategories);
    }, []);

    useEffect(() => {
        // Filter products by category
        let filteredProducts = buttonPressed === 'all' ? products : products.filter(product => product.category === buttonPressed);
        
        // Filter products by subcategory
        filteredProducts = subCatButtonPressed === '' ? filteredProducts : filteredProducts.filter(product => 
            product.subcategory.toLowerCase() === subCatButtonPressed.toLowerCase())

        // Filter by search term
        filteredProducts = searchTerm === '' ? filteredProducts : filteredProducts.filter(product => 
            product.name.toLowerCase().includes(searchTerm.toLowerCase()));
        
        // Sort ond save
        setfProdcuts(filteredProducts.sort((a, b) => a.name.localeCompare(b.name)));
    }, [buttonPressed, subCatButtonPressed, searchTerm, products]);
    
    return (
        <div className="App">
            <div className='product-container'>
                <div className='product-intro overlay'>
                    <div className='product-header'>
                        <h2 data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">Product Catalog</h2>
                    </div>
                </div>

                <div className='product-catalog'>
                    <div className='products' data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">
                        <div className='nav-bar'>
                            <button onClick={handleButtonClick} value={"all"} className='selected-button'>All</button>
                            {
                                [...new Set(products.map(product => product.category))].sort()
                                    .map(category => (
                                                <div key={category}>
                                                    <button onClick={handleButtonClick} value={category}>
                                                        {category}
                                                    </button>
                                                    {subCategory[buttonPressed] 
                                                        && buttonPressed===category 
                                                        && subCategory[buttonPressed][0]
                                                        && subCategory[buttonPressed].map(subcat => 
                                                            <div key={subcat} className='subcategory'>
                                                                <button onClick={handleSubCatButtonClick} value={subcat}>
                                                                    {subcat}
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                ))
                            }
                        </div>
                        <div className='product-list'>
                            <div className='product-search'>
                                <input 
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleChange} 
                                    placeholder="Search..."
                                />
                            </div>
                            {fProducts.map(item => (
                                <ProductCard key={item.name} item={item}/>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        
            <Footer/>
        </div>
    );
}

export default Products;