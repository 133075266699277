import './NavBar.scss'
import Logo from './../../Assests/logo_wide.png'
import tcaLogo from './../../Assests/logo_tca.png'
import { Outlet } from "react-router-dom";
import StyledLink from "../../Utils/js/StyledButton";
import {useState} from "react";

function NavBar() {
    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () =>{
        if(window.scrollY >= 80){
            setColorchange(true);
        }
        else{
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    return(
        <>
            <div className={colorChange ? 'navbar colorChange' : 'navbar'}>
                <div className="logo"><img srcSet={Logo} alt="Maxitherm Logo"/></div>
                <StyledLink to="/"><div className="navbar-item">Home</div></StyledLink>
                <StyledLink to="/products"><div className="navbar-item">Products</div></StyledLink>
                <StyledLink to="/projects"><div className="navbar-item">Projects</div></StyledLink>
                <StyledLink to="/about"><div className="navbar-item">About Us</div></StyledLink>
            </div>
            <Outlet />
        </>
    );
}

export default  NavBar;