import './HomePage.scss';

import { useEffect } from 'react';

import Factory from './../../Assests/factory_bg.mp4';
import StyledLink from "../../Utils/js/StyledButton";
import Footer from "../../Components/Footer/Footer";

import factoryImage from "../../Assests/factory.jpg";
import tcaLogo from './../../Assests/logo_tca.png';

import boiler from './../../Assests/offerings-icons/boiler.svg';
import absorptionCooling from './../../Assests/offerings-icons/absorption-cooling.svg';
import airPollution from './../../Assests/offerings-icons/air-pollution.svg';
import chemicals from './../../Assests/offerings-icons/chemicals.svg';
import energySolutions from './../../Assests/offerings-icons/energy-solutions.svg';
import processCooling from './../../Assests/offerings-icons/process-cooling.svg';
import processHeating from './../../Assests/offerings-icons/process-heating.svg';
import waterWaste from './../../Assests/offerings-icons/water-waster.svg';

import trustIcon from './../../Assests/why-icons/trust.png';
import ontimeIcon from './../../Assests/why-icons/on-time.png';
import technicalIcon from './../../Assests/why-icons/technical.png';
import turnkeyIcon from './../../Assests/why-icons/turnkey-project.png';
import precisionIcon from './../../Assests/why-icons/precision.png';
import comprehensiveIcon from './../../Assests/why-icons/comprehensive.png';

import indiaMap from '../../Assests/india_map.png';

function HomePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="home">
            <div className="intro">
                <video autoPlay loop muted id="video">
                    <source src={Factory} type='video/mp4'/>
                </video>
                <div className="video-overlay"></div>
                <div className="intro-content">
                    <h2 data-aos="fade-right" data-aos-duration="600">SUSTAINABLE SOLUTIONS FOR</h2>
                    <h1 data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">ENERGY & ENVIRONMENT</h1>
                    <p data-aos="fade-right" data-aos-duration="600" data-aos-delay="600">MaxiTherm is one-stop utility partner to both industrial and commercial segments, helping customers achieve better resource productivity and bottom lines while maintaining a better environment.</p>
                    <StyledLink to="/projects"><button>See Our Projects</button></StyledLink>
                </div>
            </div>

            <div className="fold2 two-column">
                <div className='column'>
                    <img srcSet={factoryImage}/>
                </div>
                <div className='column' data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">
                    <div className='text-container'>
                        <div>
                            <img srcSet={tcaLogo} alt="Thermax Channel Associate Logo" style={{height: '100px', width: 'auto'}} />
                        </div>
                        <h1>Heating Efficiency With Precision</h1>
                        <h3>Maxitherm Works brings Thermax quality and engineering to industrial heating solutions. As Channel Associate and Project franchisee, our expert team handles complex boiler, water treatment, and chemical system projects with quality workmanship</h3>
                    </div>
                </div>
            </div>
            
            <div className="fold3">
                <div className='offerings-header'>We offer</div>
                <div className='offerings-container'>
                    <div className='offerings-item'>
                        <img srcSet={boiler} style={{'--c': '#F36534'}}/>
                        <p>Boiler & Heater</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={processHeating} style={{'--c': '#EF6232'}}/>
                        <p>Process Heating</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={energySolutions} style={{'--c': '#EC8929'}}/>
                        <p>Energy Solutions</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={airPollution} style={{'--c': '#8AC049'}}/>
                        <p>Air Pollution Control</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={waterWaste} style={{'--c': '#28B9B8'}}/>
                        <p>Water & Waste Solutions</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={chemicals} style={{'--c': '#7A8AC4'}}/>
                        <p>Chemicals</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={processCooling} style={{'--c': '#2688C8'}}/>
                        <p>Process Cooling</p>
                    </div>
                    <div className='offerings-item'>
                        <img srcSet={absorptionCooling} style={{'--c': '#2688C8'}}/>
                        <p>Absorption Cooling</p>
                    </div>
                </div>
                <StyledLink to="/products"><button>Explore our products</button></StyledLink>
            </div>

            <div className="fold4">
                <div className='why-header'> Why choose Maxitherm Works</div>
                <div className='why-container'>
                    <div className='why-item'>
                        <h4>TRUSTED PARTNERS LIKE</h4>
                        <div className='why-item-sub'>
                            <img srcSet={trustIcon}/>
                            <div>
                                <p>Thermax Limited</p>
                            </div>
                        </div>

                    </div>
                    <div className='why-item'>
                        <h4>ON TIME DELIVERY</h4>
                        <div className='why-item-sub'>
                            <img srcSet={ontimeIcon}/>
                            <div>
                                <p>Commited to meeting project timelines</p>
                            </div>
                        </div>
                    </div>
                    <div className='why-item'>
                        <h4>TECHNICAL EXPERTISE</h4>
                        <div className='why-item-sub'>
                            <img srcSet={technicalIcon}/>
                            <div>
                                <p>Specialized knowledge for optimal solutions</p>
                            </div>
                        </div>
                    </div>
                    <div className='why-item'>
                        <h4>TURNKEY PROJECTS</h4>
                        <div className='why-item-sub'>
                            <img srcSet={turnkeyIcon}/>
                            <div>
                                <p>Custom built systems for your exact specifications</p>
                            </div>
                        </div>
                    </div>
                    <div className='why-item'>
                        <h4>PRECISION WORKMANSHIP</h4>
                        <div className='why-item-sub'>
                            <img srcSet={precisionIcon}/>
                            <div>
                                <p>Flawless fabrication and build quality</p>
                            </div>
                        </div>
                    </div>
                    <div className='why-item'>
                        <h4>COMPREHENSIVE OFFERINGS</h4>
                        <div className='why-item-sub'>
                            <img srcSet={comprehensiveIcon}/>
                            <div>
                                <p>Boilers, water treatment, chemicals - all your needs met</p>
                            </div>
                        </div>
                    </div>

                </div>
                <StyledLink to="/about"><button>Learn our story</button></StyledLink>

            </div>

            <div className="fold5">
                {/* <div className='national-header'> Nation Wide Projects</div> */}

                <div className="two-column">
                    <div className='column'>
                        <div className='text-container'>
                            <h1>Your Partners in Efficiency, <br></br>India-Wide</h1>
                            <h3>With Maxitherm Works as your trusted local associate, you gain responsive, hands-on support paired with the strength of an industry leader. Our full-fledged regional office provides specialized attention and custom solutions for your specific heating efficiency and sustainability needs. While relying on local guidance for installation to optimized operation, you further benefit from broad expertise and reliability that large-scale facilities depend on nationwide. For big project impacts delivered locally, choose Maxitherm.</h3>
                        </div>
                    </div>
                    <div className='column india-map' data-aos="fade-left" data-aos-duration="600" data-aos-delay="600">
                        <img srcSet={indiaMap}/>
                    </div>
                 </div>
            </div>

            <Footer/>
        </div>
    );
}

export default HomePage;