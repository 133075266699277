import Footer from '../../Components/Footer/Footer';
import './Projects.scss';

import projects from '../../Data/projects';

import ReactModal from 'react-modal';

import { useState, useEffect, useRef } from 'react';

import nextArrow from '../../Assests/next-arrow.png';
import prevArrow from '../../Assests/prev-arrow.png';

function Projects() {

    const [isOpen, setIsOpen] = useState(false);
    const [openProject, setOpenProject] = useState();
    function toggleModal() {
        setIsOpen(!isOpen);
      }

    function handleProjectClick(project) {
        setOpenProject(project);
        toggleModal();
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => {
        setActiveIndex(activeIndex === openProject.images.length - 1 ? 0 : activeIndex + 1);
    }
      
    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? openProject.images.length - 1 : activeIndex - 1);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const containerRef = useRef();

    return (
        <div className="App">
            <div className='project-container'>
                <div className='project-intro overlay'>
                    <div className='project-header'>
                        <h2 data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">Project Catalog</h2>
                    </div>
                </div>

                <div className='project-catalog' data-aos="fade-up" data-aos-duration="600" data-aos-delay="600">
                    <div ref={containerRef} className='projects' >
                        {projects &&
                            projects.map( project => (
                                <div key={project.projectname+project.clientname} className='project-item' onClick={() => handleProjectClick(project)}>
                                    <img src={project.thumbnail}/>
                                    <div className='project-details'>
                                        <div className='project-name'>{project.projectname}</div>
                                        <div className='project-client'>{project.clientname}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={() => containerRef.current.scrollLeft += 300}>
                        <img srcSet={nextArrow} className='scroll-arrow'></img>
                    </div>
                    <ReactModal 
                        isOpen={isOpen} 
                        onRequestClose={toggleModal} 
                        ariaHideApp={false}
                        style={{
                            overlay: {
                                marginTop: '12vh',
                                backgroundColor: 'rgba(50, 50, 50, 0.1)',
                            },
                            content: {
                                position: 'fixed',
                                top: '55%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '70%',
                                height: '70%',
                            }
                        }} 
                    >   
                    { isOpen &&
                        <div className='project-modal'>
                            <div className='project-modal-info'>
                                <div className='project-name'>Project : {openProject.projectname}</div>
                                <div className='project-client'> Client : {openProject.clientname}</div>
                                <div className='project-location'>{openProject.location}</div>
                                <br></br>
                                <br></br>
                                <div className=''>{openProject.description}</div>
                            </div>
                            <div className='project-carousel'>
                                { openProject.images[activeIndex].endsWith('.mp4') ? 
                                    <video muted controls controlsList='noremoteplayback novolume nodownload'>
                                        <source src={openProject.images[activeIndex]} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    :
                                    <img src={openProject.images[activeIndex]} alt="carousel" />
                                }
                                <img className='arrow left-arrow' onClick={handlePrev} srcSet={prevArrow}/>
                                <img className='arrow right-arrow' onClick={handleNext} srcSet={nextArrow}/>
                            </div>
                        </div>
                    }
                    </ReactModal>
                </div>

            </div>

            <Footer/>
        </div>
    );
}

export default Projects;