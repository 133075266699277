import './Footer.scss'
import Logo from './../../Assests/logo_wide.png'
import StyledLink from "./../../Utils/js/StyledButton";

function Footer() {
    return(
        <>
        <div className="footer">
            <div className="footer-col">
                <div className="footer-about">
                    <div><img srcSet={Logo} width={250} alt="Maxitherm Logo"/></div>
                    <br></br>
                    <div className="footer-about-content">
                        Maxitherm is providing sustainable solutions in energy and environment.
                        It has, over the years, developed significant expertise in the areas of
                        waste heat recovery, waste to energy, green power and steam generation,
                        air pollution abatement, process improvement chemicals, solar energy,
                        water treatment and recycling.
                    </div>
                </div>
            </div>
            <div className="footer-col">
                <h3>Useful Links</h3>
                <div className="footer-links">
                    <div><StyledLink to="/products">Product Catalog</StyledLink></div>
                    <div><StyledLink to="/projects">Projects</StyledLink></div>
                    <div><StyledLink to="/about">About Us</StyledLink></div>
                    <div><a href="https://www.thermaxglobal.com" target="_blank" rel="noreferrer">Thermax Global</a></div>
                </div>
            </div>
            <div className="footer-col">
                <h3>Contact Us</h3>
                <div className="footer-links">
                    <div><a href="mailto:maxitherm.works@gmail.com">maxitherm.works@gmail.com</a></div>
                    <div><a href="tel:+919822806428">+91-98228-06428</a></div>
                    <br></br>
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1345.083803584352!2d75.36252518124502!3d19.87825024314792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdba29347fff05d%3A0x6f1a635441c3854b!2sCannought%20Garden!5e0!3m2!1sen!2sus!4v1703909767745!5m2!1sen!2sus" height="200" style={{border:0}} ></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div className='footnote'>
            © Copyright 2024 Maxitherm Works. All Rights Reserved. Made with &#10084; in California
        </div>
        </>
    );
}

export default  Footer;