import Footer from '../../Components/Footer/Footer';
import './AboutUs.scss';

import { useEffect } from 'react';

import image1 from '../../Assests/team-photo-1.jpg';
import image2 from '../../Assests/team-photo-2.jpg';

function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="App">
            <div className='about-container'>
                <div className='about-intro overlay'>
                    <div className='about-header'>
                        <h2 data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">Our Story</h2>
                    </div>
                </div>
                <div className='about-content'>
                    <div className='about-fold'>
                        <div>
                            <p>At Maxitherm Works, we deliver specialized solutions for industrial heating efficiency as a Channel Associate and Project Franchisee for Thermax Ltd. Since our establishment, we have provided reliable support for process steam and power needs across sectors.</p>
                            <p>With a full-fledged independent office based in Aurangabad, our experienced team offers localized service and expertise catered to the requirements of businesses in the region. We combine responsiveness and individual attention of a smaller provider with the advanced engineering and reliability of Thermax’s industry-leading technologies.</p>
                        </div>
                        <div data-aos="slide-up" data-aos-duration="600" data-aos-delay="300">
                            <img srcSet={image1} />
                        </div>
                    </div>
                    <div className='about-fold'>
                        <div data-aos="slide-up"
                            data-aos-duration="600">
                            <img srcSet={image2} />
                        </div>
                        <div>
                            <p>Our strengths lie in understanding the unique demands of industrial production and utilizing Thermax’s equipments to create tailored solutions. With a collaborative approach across departments, we handle intricate projects end-to-end - from system design and precision manufacturing to installation, maintenance and replacement services.</p>
                            <p>As the regional industry evolves, Maxitherm will continue assisting factories, refineries and other facilities upgrade their process heating infrastructure for the future through energy efficient offerings. Our goal is to enable industries drive growth and output while achieving operational excellence sustainably.</p>
                        </div>
                        
                    </div>
                </div>
            </div>


            <Footer/>
        </div>
    );
}

export default AboutUs;