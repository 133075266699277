export default [
    {
        name: 'Maxfloc-A107',
        category: 'Chemicals',
        subcategory: 'Poly Chemical',
        description: 
            `MAXFLOC-A107 is Ultra High Molecular Weight, Medium to Strong Anionic Polymer in powder form. MAXFLOC-A107 improve the efficiency of solid / liquid separation. <br> <br>
            Product Features : <br>
                <ul>
                    <li>Solid product in powder form
                    <li>Easily soluble in water
                    <li>Effective over a broad pH range
                </ul>
            Principal uses : <br>
                <ul>
                    <li>Clarification , sedimentation & thickening process
                    <li>As a dewatering agent
                    <li>Flocculation
                </ul>
            Application : <br>
                <ul>
                    <li>Textile ETP : For settlings of coloring matter in primary & secondary clarifiers
                    <li>Sludge dewatering : Thickening & centrifuge in coal washeries & other industrial ETP
                    <li>Chemicals Industries - ETP
                    <li>Coal Washeries: settlings of coal refuse in thickener & Belt Press
                </ul>
            `,
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxfloc-C11',
        category: 'Chemicals',
        subcategory: 'Poly Chemical',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxfloc-20',
        category: 'Chemicals',
        subcategory: 'Poly Chemical',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxtreat 9001',
        category: 'Chemicals',
        subcategory: 'RO Chemical',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxtreat 9202',
        category: 'Chemicals',
        subcategory: 'RO Chemical',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxtreat 9206',
        category: 'Chemicals',
        subcategory: 'RO Chemical',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Thermopac_VTcy',
        category: 'Thermic Fluid Heater',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Thermopac-TP-TPD-TPDI',
        category: 'Thermic Fluid Heater',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Shellmax',
        category: 'Oil & LDO fire Boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'RX Nxt',
        category: 'Oil & LDO fire Boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Aquatherm',
        category: 'Oil & LDO fire Boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Biocides',
        category: 'Cooling Tower Chemical',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Inhibitors',
        category: 'Cooling Tower Chemical',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'CPF',
        category: 'Solid fuel boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Combloc',
        category: 'Solid fuel boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Thermeon',
        category: 'Solid fuel boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Combipack',
        category: 'Solid fuel boiler',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: '3100 L',
        category: 'Boiler water chemical',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: '3006',
        category: 'Boiler water chemical',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: '3333',
        category: 'Boiler water chemical',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxclean_07',
        category: 'Bag filter',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'Maxclean 05',
        category: 'Bag filter',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
    {
        name: 'ESP brochure',
        category: 'Bag filter',
        // subcategory: 'boiler2',
        path: '../../Assests/factory.jpg',
    },
]