import './App.scss';
import HomePage from "./Pages/HomePage/HomePage";
import Products from "./Pages/Products/Products";
import Projects from "./Pages/Projects/Projects";
import AboutUs from "./Pages/AboutUs/AboutUs";
import NavBar from "./Components/NavBar/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { SpeedInsights } from "@vercel/speed-insights/react";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Footer from './Components/Footer/Footer';


function App() {

  AOS.init({
    once: true,
  });
  
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<NavBar />}>
                    <Route index element={<HomePage />} />
                    <Route path="products" element={<Products />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="about" element={<AboutUs />} />
                </Route>
            </Routes>
        </BrowserRouter>
        <SpeedInsights/>
    </div>
  );
}

export default App;
