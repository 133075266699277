import './ProductCard.scss';

import {useState, useEffect} from 'react';

import ReactModal from 'react-modal';

import image from '../../Assests/factory.jpg';
import nextIcon from '../../Assests/products/next-icon.png';
import waIcon from '../../Assests/whatsapp-icon.png';
import emailIcon from '../../Assests/email-icon.png';

function ProductCard(props) {

    const [isOpen, setIsOpen] = useState(false);
    function toggleModal() {
        setIsOpen(!isOpen);
      }

    function handleNextClick() {
        toggleModal()
    }

    
    return (
        <div className='product-card'>
            <div className='product-image'><img srcSet={image}/></div>
            <div className='product-header'>{props.item.name}</div>
            <div className='next'><img srcSet={nextIcon} onClick={handleNextClick}/></div>
            
            <ReactModal 
                isOpen={isOpen} 
                onRequestClose={toggleModal} 
                ariaHideApp={false}
                style={{
                    overlay: {
                        marginTop: '12vh',
                        backgroundColor: 'rgba(50, 50, 50, 0.7)',
                    },
                    content: {
                        position: 'fixed',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        height: '70%',
                    }
                }} 
            >   
                <div className='product-modal'>
                    <div className='product-details'>
                        <h2>{props.item.name.toUpperCase()}</h2>
                        <div dangerouslySetInnerHTML={{__html: props.item.description}}></div>
                    </div>
                    <div className='product-enquire'>
                        <h3>Enquire Now:</h3>
                        <a href={`mailto:maxitherm.works@gmail.com?subject=Product Inquiry ${props.item.name}&body=I am interested in learning more about your ${props.item.name} product.`} target="_blank"><img srcSet={emailIcon} alt="Email"/></a>
                        <a href={`https://wa.me/919762916076?text=I'm%20interested%20in%20${props.item.name}`} target="_blank"><img srcSet={waIcon} alt="WhatsApp"/></a>
                    </div>
                </div>
            </ReactModal>
        </div>
    );

}

export default ProductCard;