export default [
    {
        projectname :'Chimney Erection',
        clientname :'Mylan',
        location : 'Auragabad, Maharashtra',
        description : 'This project included erection of two full size chimneys at 100ft tall',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-1.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mylan/mylan-vid-1.mp4',
                ]
    },
    {
        projectname :'Boiler Chimney',
        clientname :'Agrocean',
        location : 'Majalgaon, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-4.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-5.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/agrocean/agrocean-vid-1.mp4',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Madurai',
        location : 'Madurai, Tamil Nadu',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/madurai/madurai-1.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/madurai/madurai-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/madurai/madurai-2.jpeg',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Mahalaxmi',
        location : 'Beed, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mahalaxmi/mahalaxmi-1.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mahalaxmi/mahalaxmi-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mahalaxmi/mahalaxmi-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mahalaxmi/mahalaxmi-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/mahalaxmi/mahalaxmi-vid-1.mp4',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Minakshi',
        location : 'Paithan, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi-chimney/minakshi-1.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi-chimney/minakshi-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi-chimney/minakshi-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi/minakshi-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi/minakshi-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi/minakshi-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi/minakshi-6.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/minakshi/minakshi-vid-1.mp4',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Dhanraj',
        location : 'Latur, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-2.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-5.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/dhanraj/dhanraj-vid-1.mp4',
                ]
    },
    {
        projectname :'Economiser Coil',
        clientname :'',
        location : '',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/economiser/economiser-3.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/economiser/economiser-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/economiser/economiser-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/economiser/economiser-3.jpeg',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Narmada Kille',
        location : 'Dharur, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-5.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-5.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-6.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-7.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-8.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-10.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/narmada/narmada-13.jpeg',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Darshana',
        location : 'Barshi, Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/darshana/darshana-1.jpeg',
        images : [
            'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/darshana/darshana-1.jpeg',
            'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/darshana/darshana-5.jpeg',
            'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/darshana/darshana-6.jpeg',
            'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/darshana/darshana-7.jpeg',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'Parihar Milan Mithai',
        location : 'Maharashtra',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-5.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-5.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-3.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/parihar/parihar-6.jpeg',
                ]
    },
    {
        projectname :'Full Project',
        clientname :'SP',
        location : 'N/A',
        description : 'This is a sample project description',
        thumbnail : 'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-14.jpeg',
        images : [
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-14.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-1.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-2.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-4.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-5.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-8.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-9.jpeg',
                    'https://pub-4b4643eec26f471ca2895fc1d4954df5.r2.dev/Assests/projects/sp/sp-15.jpeg',
                ]
    },
]